@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;600;700&display=swap');

$__body__font__family:'Nunito', sans-serif;


$__bold:700;
$__semibold:600;
$__medium:500;
$__regular:400;
$__light:300;

$__body__font__size:14px;
$__menu__font__size:16px;
$__button__font__size:18px;
$__font__size__8:8px;
$__font__size__10:10px;
$__font__size__11:11px;
$__font__size__12:12px;
$__font__size__13:13px;
$__font__size__14:14px;
$__font__size__15:15px;
$__font__size__16:16px;
$__font__size__17:17px;
$__font__size__19:19px;
$__font__size__18:18px;
$__font__size__20:20px;
$__font__size__22:22px;
$__font__size__24:24px;
$__font__size__26:26px;
$__font__size__28:28px;
$__font__size__30:30px;
$__font__size__36:36px;
$__font__size__40:40px;
$__font__size__42:42px;
$__font__size__50:50px;

h1{
	font-weight:$__normal;
	font-size:$__font__size__40;
	@include respond-below(custom767){
		font-size:$__font__size__26 + 1;
	}
}
h2{
	font-weight:$__normal;
	font-size:$__font__size__30;
	@include respond-below(custom767){
		font-size:$__font__size__22 + 1;
	}
}
h3{
	font-weight:$__normal;
	font-size:$__font__size__24;
	@include respond-below(custom1023){
		font-size:$__font__size__20;
	}
	@include respond-below(custom767){
		font-size:$__font__size__16;
	}
}
h4{
	font-weight:$__normal;
	font-size:$__font__size__20 + 1;
	@include respond-below(custom1023){
		font-size:$__font__size__18;
	}
	@include respond-below(custom767){
		font-size:$__font__size__18;
	}
}
h5{
	font-weight:$__normal;
	font-size:$__font__size__18 + 1;
	@include respond-below(custom1023){
		font-size:$__font__size__18;
	}
}
h6{
	font-weight:$__normal;
	font-size:$__font__size__18;
	@include respond-below(custom1023){
		font-size:$__font__size__16;
	}
}
